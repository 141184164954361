import useStaffOverviewModel from "../../model/StaffOverview/useStaffOverviewModel";

const useStaffOverviewViewModel = () => {
  const { staffOverviewData } = useStaffOverviewModel();
  return {
    staffOverviewData,
  };
};

export default useStaffOverviewViewModel;
