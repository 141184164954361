import Cards from "../UI/Cards/Cards";
import { tooltipContent } from "../../content";
import Tooltips from "../UI/Tooltip/Tooltip";
import { useEffect, useState } from "react";
import ComplianceBucketButtons from "../ComplianceBucketButtons/ComplianceBucketButtons";
import CircularHeatmap from "../CircularHeatmap/CircularHeatmap";
import { formatPercentage } from "../../utils";
import MissingData from "../MissingData/MissingData";
import { missingDataAction } from "../../constants";
import useThresholdButtons from "../Filters/hooks/useThresholdButtons";

interface HeatmapProps {
  data: {
    data: { x: number; y: number; name?: string; percentage: number }[];
  }[];
  viewingBy: string;
  xLabels: string[];
  yLabels: string[];
  id?: string;
}

function Heatmap({ xLabels, yLabels, ...props }: HeatmapProps) {
  const { selectedButton, setSelectedButton, thresholdOne, thresholdTwo } =
    useThresholdButtons(props.data);

  const [heatmapData, setHeatmapData] = useState(props.data);

  const replaceYValuesBetweenThresholds = (
    dataArray: { data: { x: number; y: number }[] }[],
    lowerThreshold: number,
    upperThreshold: number
  ) => {
    const updatedDataArray = dataArray?.map((facility) => ({
      ...facility,
      data: facility.data.map((entry) => ({
        ...entry,
        percentage:
          formatPercentage(entry.y) >= lowerThreshold &&
          formatPercentage(entry.y) <= upperThreshold
            ? formatPercentage(entry.y)
            : -1,
      })),
    }));

    return updatedDataArray;
  };

  const allDataLengthsZero = heatmapData?.every(
    (data) => data.data.length === 0
  );

  useEffect(() => {
    setHeatmapData(
      replaceYValuesBetweenThresholds(props.data, thresholdOne, thresholdTwo)
    );
  }, [selectedButton, props.data, thresholdOne, thresholdTwo]);

  return (
    <div className="heatmap" data-testid="svg-heatmap" id="heatmap">
      <Cards
        headerLeft={<h2>Hand Hygiene Performance by {props.viewingBy}</h2>}
        headerRight={
          <Tooltips
            id={"heatmap-tooltip"}
            content={tooltipContent.executiveDashboard["en-US"]}
          ></Tooltips>
        }
      >
        {!props.data || allDataLengthsZero ? (
          <>
            <MissingData
              message={"No data for Heatmap"}
              action={missingDataAction}
            />
          </>
        ) : (
          <>
            <ComplianceBucketButtons
              selectedButton={selectedButton}
              onSelectButton={setSelectedButton}
            />
            <CircularHeatmap
              id={props.id}
              xLabels={xLabels}
              yLabels={yLabels}
              dataSets={heatmapData}
            />
          </>
        )}
      </Cards>
    </div>
  );
}

export default Heatmap;
