import { formatSummaryMetric } from "../../utils";
import Cards from "../UI/Cards/Cards";
import Tooltips from "../UI/Tooltip/Tooltip";

type SummaryCardProps = {
  title: string;
  tooltipContent: string;
  index: number;
  data: [key: string, value: number];
};

function SummaryCard(props: SummaryCardProps) {
  const tooltipId = `SummaryCard-tooltip-${props.index}`;
  return (
    <Cards
      headerLeft={<h2>{props.title}</h2>}
      headerRight={<Tooltips id={tooltipId} content={props.tooltipContent} />}
    >
      <div className="performance-container">
        <div>
          <p className="semi-bold"></p>
          <h1>{formatSummaryMetric(props.data[0], props.data[1])}</h1>
        </div>
      </div>
    </Cards>
  );
}

export default SummaryCard;
