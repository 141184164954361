import { RoomOverview } from "../../model/types";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";

// if the number of visits is 0 then show something differenent than if most visited by info is just missing
// also need to handle case of how unit_ids cannot be just the string null in the new pattern

type RoomOverviewTableData = {
  roomName: string;
  visitLengthAvg: string;
  numberOfVisits: number;
  longestVisit: string;
  mostVisitedByUser: string;
};

export function transformRoomOverview(
  roomOverview: RoomOverview
): RoomOverviewTableData {
  const firstName = roomOverview.mostVisitedByUser?.firstName;
  const lastName = roomOverview.mostVisitedByUser?.lastName;
  const mostVisitedByUser = roomOverview.mostVisitedByUser
    ? `${firstName} ${lastName}`
    : "N/A";

  return {
    roomName: roomOverview.room?.name,
    visitLengthAvg: convertSecondsToHoursMinutesSeconds(
      roomOverview.visitLengthAvg
    ),
    numberOfVisits: roomOverview.visitCount || 0,
    longestVisit: convertSecondsToHoursMinutesSeconds(
      roomOverview.longestVisit
    ),
    mostVisitedByUser,
  };
}
