import { useAsyncValue } from "react-router";
import { tooltipContent } from "../../content";
import { RoomsOverviewSummaryDTO } from "../../model/types";
import SummaryCard from "../SummaryCard/SummaryCard";

function Summary() {
  const summary = useAsyncValue() as RoomsOverviewSummaryDTO;
  const { averages } = summary;
  if (!averages) {
    console.error("Averages not found in summary");
    return null;
  }

  const titleMapper = {
    timeAlone: "Percentage of Time Patient was Alone",
    timeBetween: "Average Length of Time Between Visits",
    visitLength: "Average Length of Visit",
  };

  return (
    <>
      {Object.entries(averages).map(([key, value], i) => {
        const tooltipText = tooltipContent[key]["en-US"];
        return (
          <div className="ni-visit-overview-summary-card" key={i}>
            <SummaryCard
              index={i}
              title={titleMapper[key]}
              data={[key, value]}
              tooltipContent={tooltipText}
            />
          </div>
        );
      })}
    </>
  );
}

export default Summary;
