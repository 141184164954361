import Cards from "../UI/Cards/Cards";
import Tooltips from "../UI/Tooltip/Tooltip";
import MissingData from "../MissingData/MissingData";
import { missingDataMessage, missingDataAction } from "../../constants";
import Award from "@untitled-ui/icons-react/build/cjs/Award01";
import { tooltipContent } from "../../content";

type BestDayProps = {
  bestTimePeriod: { performance: number; date: string } | null;
  aggregateLabel: string | null;
};

function BestDay(props: BestDayProps) {
  const { bestTimePeriod, aggregateLabel } = props;

  let missingData = (
    <MissingData
      className="margin-top"
      message={missingDataMessage}
      action={missingDataAction}
    />
  );

  return (
    <>
      {!bestTimePeriod ? (
        <Cards>{missingData}</Cards>
      ) : (
        <Cards fullWidth={true} className="full-height display-flex-center">
          <div className="best-date vertically-align">
            <div className="title-tooltip-container">
              <div>
                {" "}
                <Tooltips
                  id="bestDateTooltip"
                  content={tooltipContent.bestDate["en-US"]}
                />
              </div>
              <div>
                <h2>Best {aggregateLabel}</h2>
              </div>
            </div>

            <div className="center-content metrics-svg">
              <Award />
            </div>
            <div className="center-content">
              <h1>{bestTimePeriod?.performance}%</h1>
              <p className="semi-bold">{bestTimePeriod?.date}</p>
            </div>
          </div>
        </Cards>
      )}
    </>
  );
}

export default BestDay;
