import { oneWeekDateRange } from "../../constants";
import { RoundingDTO, NodeData, ShiftPerformance } from "../../model/types";
import { DataSeries } from "../../types/types";
import { TransformedRounding } from "../../viewmodel/types";
import { transformFilters } from "../utils";

export function transformRounding(
  roundingObj: RoundingDTO
): TransformedRounding {
  const params = roundingObj?.params;
  const filters = transformFilters(params);

  let startDate, endDate;
  const dateRange = params?.date_range?.value;

  if (!dateRange) {
    startDate = oneWeekDateRange.startDate;
    endDate = oneWeekDateRange.endDate;
  } else {
    startDate = new Date(dateRange[1]);
    endDate = new Date(dateRange[2]);
  }

  const aggregateData = roundingObj.aggregateData;
  let dataSeries: DataSeries[];
  if (!aggregateData) {
    dataSeries = [];
  } else {
    dataSeries = aggregateData?.flatMap(({ node }: { node: NodeData }) => {
      return node?.shifts?.map((shiftPerformance: ShiftPerformance) => {
        const data = shiftPerformance?.breakdownByDateRange?.map(
          ({ percentage, dateRange }) => ({
            percentage,
            name: dateRange,
          })
        );

        return {
          data,
          name: `${node.name}: ${shiftPerformance.name}`,
          showInLegend: true,
          visible: true,
        };
      });
    });
  }

  const uuid = roundingObj?._report?.uuid;

  return {
    uuid,
    allNodes: roundingObj?.allNodes,
    excelUrl: roundingObj?.export,
    startDate,
    endDate,
    filters,
    dataSeries,
  };
}
