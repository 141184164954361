import { useAsyncValue } from "react-router";
import { RoomsOverviewRoomsDTO } from "../../model/types";
import Table from "../Table/Table";
import { transformRoomOverview } from "../../transformers/visitOverview/transformRoomOverview";

function RoomsTable() {
  const tableData = useAsyncValue() as RoomsOverviewRoomsDTO;
  const { roomOverviews } = tableData;

  if (!tableData) {
    console.error("tableData not found");
    return null;
  }

  const columns = [
    {
      name: "roomName",
      label: "Room Name",
    },
    {
      name: "visitLengthAvg",
      label: "Average Length of Visit",
    },
    {
      name: "numberOfVisits",
      label: "Number of Visits",
    },
    {
      name: "longestVisit",
      label: "Longest Visit",
    },
    {
      name: "mostVisitedByUser",
      label: "Most Visited By",
    },
  ];

  const data = roomOverviews.map(transformRoomOverview);

  return <Table title={"Rooms Overview"} data={data} columns={columns}></Table>;
}

export default RoomsTable;
