import { defer } from "react-router-dom";
import { get } from "../../api/laravelApi";
import {
  roomsOverviewParamsEndpoint,
  roomsOverviewSummaryEndpoint,
  roomsOverviewTableEndpoint,
} from "../../constants";

export const RoomsOverviewLoader = async ({ request }) => {
  const baseUrl = new URL(request.url);
  const customerIds = baseUrl.searchParams.getAll("customersIds");
  const facilityIds = baseUrl.searchParams.getAll("facilitiesIds");
  const unitIds = baseUrl.searchParams.getAll("unitsIds");
  const unitTypeIds = baseUrl.searchParams.getAll("UnitTypesIds");
  const startDate = baseUrl.searchParams.get("startDate");
  const endDate = baseUrl.searchParams.get("endDate");

  const params = {
    customerIds,
    facilityIds,
    unitIds,
    unitTypeIds,
    startDate,
    endDate,
  };

  const paramsForDataEndpoints = Object.keys(params).reduce((acc, key) => {
    // This is a temporary solution. The params endpoint uses an older interface
    // and expects a 'null' string to be passed instead of omitting the key.
    if (params[key]?.length && params[key][0] !== "null") {
      acc[key] = params[key];
    }
    return acc;
  }, {});

  const filters = get(roomsOverviewParamsEndpoint, params);
  const summary = get(roomsOverviewSummaryEndpoint, paramsForDataEndpoints);
  const roomsTable = get(roomsOverviewTableEndpoint, paramsForDataEndpoints);

  return defer({
    summary,
    roomsTable,
    filters,
  });
};
