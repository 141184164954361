import { useReducer } from "react";
import { Filter, Option, GroupedOptions } from "../../../../src/types/types";
import {
  getAllNewSelectedOptions,
  getDependencyValuesForPeerFilters,
  isGroupedOptions,
  isGroupedSelection,
  isRawOptionsWithHeaders,
  parseOptions,
  parseOptionsHeaders,
} from "./helpers";
import {
  DependencyValues,
  RawOptionsObject,
  RawOptionsWithHeaders,
} from "../types/types";

type Action =
  | {
      type: "SET_SELECTED";
      payload: { filterName: string; selected: Option[] };
    }
  | {
      type: "SET_OPTIONS";
      payload: { filterName: string; options: Option[] | GroupedOptions[] };
    }
  | { type: "SET_FILTER_DIRTY"; payload: { filterName: string } }
  | {
      type: "SET_PEER_FILTERS";
      payload: { filterName: string; peerFilters: string[] };
    }
  | { type: "SET_FILTER_CLEAN"; payload: { filterName: string } }
  | {
      type: "VALIDATE_SELECTED";
      payload: { filterName: string; options: Option[] | GroupedOptions[] };
    };

const filterReducer = (state: Filter[], action: Action): Filter[] => {
  const filterIndex = state.findIndex(
    (filter) => filter.name === action.payload.filterName
  );
  const newState = [...state];

  switch (action.type) {
    case "SET_SELECTED":
      newState[filterIndex].selection = action.payload.selected;
      return newState;
    case "SET_OPTIONS":
      newState[filterIndex].options = action.payload.options;

      return newState;
    case "VALIDATE_SELECTED":
      newState[filterIndex].selection = newState[filterIndex].selection.filter(
        (selectedOption) => {
          if (!isGroupedOptions(action.payload.options)) {
            return (
              action.payload.options.find(
                (option) => option.value === selectedOption.value
              ) !== undefined
            );
          } else {
            return (
              action.payload.options
                .map((group) => {
                  return group.options;
                })
                .flat()
                .find((option) => option.value === selectedOption.value) !==
              undefined
            );
          }
        }
      );

      // if the new options have a group label and our current selection does not
      // then we need to add that group label to each option so that we can display it to the user
      if (
        (isGroupedOptions(action.payload.options) &&
          !isGroupedSelection(newState[filterIndex].selection)) ||
        (!isGroupedOptions(action.payload.options) &&
          isGroupedSelection(newState[filterIndex].selection))
      ) {
        newState[filterIndex].selection = getAllNewSelectedOptions(
          newState[filterIndex].selection,
          action.payload.options
        );
      }

      return newState;
    case "SET_FILTER_DIRTY":
      newState[filterIndex].isDirty = true;
      return newState;
    case "SET_FILTER_CLEAN":
      newState[filterIndex].isDirty = false;
      return newState;
    case "SET_PEER_FILTERS":
      newState[filterIndex].peerFilters = action.payload.peerFilters;
      return newState;
    default:
      return state;
  }
};

export const useFilterState = (
  fetchOptions: (
    reloadingUrl: string,
    dependencyValues: DependencyValues
  ) => Promise<RawOptionsObject | RawOptionsWithHeaders>,
  initialState: {
    initialFilters: Filter[];
  } = {
    initialFilters: [],
  }
) => {
  const initialFilters = initialState.initialFilters;

  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  const setAllFiltersDirty = () => {
    filters.forEach((filter) => {
      dispatch({
        type: "SET_FILTER_DIRTY",
        payload: { filterName: filter.name },
      });
    });
    updateDirtyFilters();
  };

  const setSelected = async (filterName: string, selected: Option[]) => {
    selected = selected.filter((option) => option.value !== "null");
    dispatch({ type: "SET_SELECTED", payload: { filterName, selected } });

    await setDependentFiltersDirty(filterName);

    await updateDirtyFilters();
  };

  const setPeerFilters = async (filterName: string, peerFilters: string[]) => {
    dispatch({
      type: "SET_PEER_FILTERS",
      payload: { filterName, peerFilters },
    });
  };

  const updateDirtyFilters = async () => {
    for (const filterState of filters) {
      if (filterState.isDirty) {
        let shouldUpdate = true;
        for (const peerFilter of filterState.peerFilters) {
          const peerFilterState = filters.find(
            (filter) => filter.name === peerFilter
          );

          if (peerFilterState === undefined) {
          } else if (peerFilterState.isDirty) {
            shouldUpdate = false;
            break;
          }
        }

        if (shouldUpdate) {
          const dependencyValues = getDependencyValuesForPeerFilters(
            filterState.peerFilters,
            filters
          );

          let options = await fetchOptions(
            filterState.reloadingUrl,
            dependencyValues
          );

          let allOptions: Option[] | GroupedOptions[] = [];

          if (isRawOptionsWithHeaders(options)) {
            allOptions = parseOptionsHeaders(options);
          } else {
            allOptions = parseOptions(options);
          }

          dispatch({
            type: "SET_OPTIONS",
            payload: {
              filterName: filterState.name,
              options: allOptions,
            },
          });
          dispatch({
            type: "SET_FILTER_CLEAN",
            payload: { filterName: filterState.name },
          });

          dispatch({
            type: "VALIDATE_SELECTED",
            payload: { filterName: filterState.name, options: allOptions },
          });
        }
      }
    }

    if (filters.some((f) => f.isDirty)) {
      await updateDirtyFilters();
    }
  };

  const setDependentFiltersDirty = async (filterName: string) => {
    const convertFilterNameToState = () => {
      switch (filterName) {
        case "aggregation":
          return "aggregation";
        case "exposure type":
          return "exposure_type_ids";
        case "shift":
          return "blueprint_realtime_shift_selector";
        case "customers":
          return "customer_ids";
        case "facilities":
          return "facility_ids";
        case "units":
          return "unit_ids";
        case "rooms":
          return "room_config_ids";
        case "groups":
          return "group_ids";
        case "model":
          return "asset_model_ids";
        case "type":
          return "asset_type_ids";
        case "Unit Types":
          return "tag_ids";
        default:
          console.error("Filter name not found for ", filterName);
          return "";
      }
    };

    filters.forEach((filter) => {
      const filterName = convertFilterNameToState();

      if (filter.peerFilters.includes(filterName)) {
        dispatch({
          type: "SET_FILTER_DIRTY",
          payload: { filterName: filter.name },
        });
      }
    });
  };

  return {
    filters,
    setAllFiltersDirty,
    setSelected,
    setPeerFilters,
  };
};
