import { formatAbbreviatedNumber, getColor } from "../../utils";

export const MonthlySnapshotOptions = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: { parsed: { x: number } }) {
          return formatAbbreviatedNumber(context.parsed.x) + " opportunities";
        },
      },
    },
  },
  scales: {
    y: {
      stacked: true,
    },
    x: {
      grace: function (context: { scale: any; parsed: { x: number } }) {
        const max: number = context.scale.max;

        if (max <= 100000) {
          return "10%";
        } else if (max <= 500000) {
          return "20%";
        } else if (max <= 1000000) {
          return "30%";
        } else {
          return "5%";
        }
      },
      title: {
        display: true,
        text: "Opportunities",
        font: {
          weight: "bold" as "bold",
        },
      },
      stacked: true,
      min: 0,
      ticks: {
        callback: function (value: number | null) {
          if (value === null) return;
          return formatAbbreviatedNumber(value);
        },
      },
    },
  },
  maintainAspectRatio: false,
};

export const complianceDataPlugin = {
  id: "complianceDataPlugin",
  afterDatasetsDraw: (chart, _args, _options) => {
    const { ctx } = chart;

    let indexDataset = 0;
    if (chart.data.datasets.length === 2) {
      indexDataset = 1;
    }

    chart.data.datasets[indexDataset].data.forEach(
      (_value: string, index: string | number) => {
        const datasetArray: { x: number; y: number }[] = [];

        chart.data.datasets.forEach(
          (dataset: { data: { x: number; y: number }[] }) => {
            datasetArray.push(dataset.data[index]);
          }
        );

        // eslint-disable-next-line array-callback-return
        return datasetArray.map((data) => {
          ctx.fillStyle = getColor(data.y);
          ctx.beginPath();
          ctx.roundRect(
            chart.getDatasetMeta(indexDataset).data[index].x + 7.5,
            chart.getDatasetMeta(indexDataset).data[index].y - 17.5,
            35,
            35,
            35
          );
          ctx.fill();

          ctx.font = "bold 14px Arial";
          ctx.fillStyle = "black";
          ctx.fillText(
            " " + data.y + "%",
            chart.getDatasetMeta(indexDataset).data[index].x + 7.5,
            chart.getDatasetMeta(indexDataset).data[index].y + 2.5
          );
        });
      }
    );
  },
};
