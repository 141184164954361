import DatePicker from "../../Components/DatePicker/DatePicker";
import Performance from "../../Components/Performance/Performance";
import LineGraph from "../../Components/LineGraph/LineGraph";
import PerformanceDetail from "../../Components/PerformanceDetail/PerformanceDetail";
import Filters from "../../Components/Filters/Filters";
import { defaultDateRange, maxDayFilter, maxWeekFilter } from "../../constants";
import Stars from "../../Components/Stars/Stars";

import ApiError from "../../Components/ApiError/ApiError";
import useGroupReportViewController from "../../viewcontroller/Groups/useGroupReportViewController";
import MetricsCard from "../../Components/MetricsCard/MetricsCard";
import HardwareButton from "../../Components/HardwareButton/HardwareButton";
import Cards from "../../Components/UI/Cards/Cards";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import BestDay from "../../Components/BestDay/BestDay";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import { useEffect } from "react";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";
import OtherMetricsHH from "../../Components/OtherMetricsHH/OtherMetricsHH";
import HistogramCard from "../../Components/HistogramCard/HistogramCard";

import usePdfDownload from "../../Hooks/usePdfDownload";
import DownloadButton from "../../Components/DownloadButton/DownloadButton";
import { groupElementIds, groupTitles } from "../../constants/pdfs";

function GroupsView() {
  const {
    transformedGroupReportData: groupReportData,
    handleDateChange,
    filters,
    onFilterChange,
    applyFilters,
    toggleButton,
    bestDateAggregate,
    disableExcelDoc,
    aggregate,
    handleDatePickerChange,
    isDatePickerVisible,
    pdfTables,
    handleDatePickerButtonClick,
  } = useGroupReportViewController();

  const { total, performant } = groupReportData.tiles;
  const apiError = groupReportData.error;

  const { updateUUID } = useUUIDWithLocation();

  const { downloadPdf, loading } = usePdfDownload(
    groupElementIds,
    groupTitles,
    pdfTables,
    "Group-Report.pdf",
    "Group Report PDF"
  );

  useEffect(() => {
    updateUUID(groupReportData?.uuid);
  }, [groupReportData?.uuid, updateUUID]);

  return (
    <div>
      <div className="page-header">
        <h1>Groups</h1>
        <div className="buttons-container">
          <ExcelDownload
            disabled={disableExcelDoc}
            link={groupReportData.excelUrl}
          />
          <DownloadButton click={downloadPdf} loading={loading} cta={"PDF"} />
          <HardwareButton
            link={
              process.env.REACT_APP_AUTH_DOMAIN + "/dashboard/vitalSigns/group"
            }
          />
        </div>
      </div>
      {apiError && <ApiError message={groupReportData.error!.message} />}
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  groupReportData.startDate ?? defaultDateRange.startDate
                }
                endDateLabel={
                  groupReportData.endDate ?? defaultDateRange.endDate
                }
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  maxNumDays={maxDayFilter}
                  maxNumWeeks={maxWeekFilter}
                  aggregate={aggregate}
                  startDate={
                    groupReportData.startDate ?? defaultDateRange.startDate
                  }
                  endDate={groupReportData.endDate ?? defaultDateRange.endDate}
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFilters}
            />
          </div>
        </Cards>
      </div>

      <div className="overview-pages-grid">
        <div className="performance-card">
          <Performance reportData={groupReportData.tiles} />
        </div>
        <div className="metrics-card">
          <MetricsCard performant={performant} total={total} />
        </div>
        <div className="best-day-card">
          <BestDay
            bestTimePeriod={groupReportData.bestTimePeriod}
            aggregateLabel={bestDateAggregate}
          />
        </div>
        <div className="other-metrics-card-hh">
          <OtherMetricsHH
            weekday={groupReportData?.weekdayWeekendData?.weekday}
            weekend={groupReportData?.weekdayWeekendData?.weekend}
            shiftData={groupReportData?.shiftData ?? "No Data"}
            entry={groupReportData?.entryExitData?.Entry}
            exit={groupReportData?.entryExitData?.Exit}
          />
        </div>
        <div className="graph-card">
          <LineGraph
            title={"Performance Over Time"}
            yAxisPlotlines={groupReportData.yAxisPlotlines}
            dataSeries={groupReportData.dataSeries}
          />
        </div>
        <div className="histogram-card">
          <HistogramCard
            id={"histogram"}
            title={"Histogram of Providers"}
            data={groupReportData.userHistogram}
            tableData={groupReportData.userHistogramDrilldownData}
          />
        </div>
        <div className="performance-breakdown-card">
          <PerformanceDetail dataSeries={groupReportData.unitDataSeries} />
        </div>
        <div className="hygiene-stars-card">
          <Stars topPerformers={groupReportData.topPerformers} />
        </div>
      </div>
    </div>
  );
}

export default GroupsView;
