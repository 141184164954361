import { useEffect, useState } from "react";
import useRoundingViewModel from "../../viewmodel/Rounding/useRoundingViewModel";
import useSharedFunctions from "../sharedFunctions/useSharedFunctions";
import { dateDiffInDays } from "../../utils";

const useRoundingViewController = () => {
  const {
    transformedRoundingData,
    roundingDataTable,
    bubbleChartData,
    transformedBarGraphData,
  } = useRoundingViewModel();
  const {
    handleDateChange,
    startDate,
    endDate,
    aggregate,
    onFilterChange,
    filters,
    applyFilters,
    setSelected,
    setPeerFilters,
    toggleButton,
    disableExcelDoc,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
  } = useSharedFunctions(transformedRoundingData);

  useEffect(() => {
    transformedRoundingData.filters.forEach((filter) => {
      setPeerFilters(filter.name, filter.peerFilters!);
      setSelected(filter.name, filter.selection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState(false);
  const [disabledButton, setDisabled] = useState(false);

  useEffect(() => {
    const dateOne = new Date(startDate);
    const dateTwo = new Date(endDate);
    if (aggregate === "daily" && dateDiffInDays(dateOne, dateTwo) > 7) {
      setError(true);
      setDisabled(true);
    } else {
      setError(false);
      setDisabled(false);
    }
  }, [aggregate, startDate, endDate]);

  const [filterIsSelected, setFilterIsSelected] = useState(
    filters.some(
      (filter) => filter.name === "units" && filter.selection.length !== 0
    )
  );

  const applyFiltersWithDataTableValues = () => {
    const newFilterIsSelected = filters.some(
      (filter) => filter.name === "units" && filter.selection.length !== 0
    );

    setFilterIsSelected(newFilterIsSelected);
    applyFilters();
  };

  return {
    bubbleChartData,
    transformedRoundingData,
    aggregate,
    startDate,
    endDate,
    handleDateChange,
    filters,
    onFilterChange,
    toggleButton,
    disableExcelDoc,
    roundingDataTable,
    filterIsSelected,
    applyFiltersWithDataTableValues,
    error,
    disabledButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    transformedBarGraphData,
  };
};

export default useRoundingViewController;
