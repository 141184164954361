export const groupElementIds = ["pdf-graph", "histogram"];

export const groupTitles = ["Performance Report", "Histogram of Providers"];

export const executiveElementIds = [
  "facility-graph1",
  "facility-graph2",
  "facility-graph3",
  "facility-graph4",
  "heatmap",
  "exec-dashboard-unit-current",
  "exec-dashboard-unit-previous",
  "exec-dashboard-group-current",
  "exec-dashboard-group-previous",
];

export const executiveTitles = [
  "Facility Performance Report (Month to Date)",
  "Facility Performance Report (Previous Month)",
  "Facility Performance Report (Current Fiscal Year)",
  "Facility Performance Report (Previous Fiscal Year)",
  "Hand Hygiene Performance by Facility",
  "Performance by Unit type - Current Month",
  "Performance by Unit type - Previous Month",
  "Performance by Group type - Current Month",
  "Performance by Group type - Previous Month",
];

export const unitElementIds = ["pdf-graph", "histogram"];

export const unitTitles = ["Performance Report", "Histogram of Providers"];

export const staffElementIds = ["pdf-graph"];

export const staffTitles = ["Hand Hygiene Performance By Staff"];

export const myReportElementIds = ["pdf-graph"];

export const myReportTitles = ["My Reports Performance Over Time"];

export const individualStaffElementIds = ["pdf-graph"];

export const individualStaffTitles = ["Hand Hygiene Performance By Staff"];
