import useStaffOverviewViewModel from "../../viewmodel/StaffOverview/useStaffOverviewViewModel";

const useStaffOverviewViewController = () => {
  const { staffOverviewData } = useStaffOverviewViewModel();

  return {
    staffOverviewData,
  };
};

export default useStaffOverviewViewController;
