import { useLoaderData } from "react-router-dom";

const useStaffOverviewModel = () => {
  // todo: fill in the DTO type
  const staffOverviewData = useLoaderData() as any;

  return {
    staffOverviewData,
  };
};

export default useStaffOverviewModel;
