import { Tooltip } from "react-tooltip";
import { formatNumberWithCommas, getColor } from "../../utils";
import { Key, useEffect, useState } from "react";

const CircularHeatmap = ({ dataSets, xLabels, yLabels, id }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateSize = (totalOpportunities: string) => {
    const minSize = isMobile ? 20 : 50;
    const maxSize = isMobile ? 50 : 80;

    const maxOpportunities = Math.max(
      ...dataSets.flatMap((ds: { data: any[] }) =>
        ds.data.map((item) => parseInt(item.total_opportunities))
      )
    );

    return `${
      minSize +
      (parseInt(totalOpportunities) / maxOpportunities) * (maxSize - minSize)
    }px`;
  };

  const percentage = (dataPoint: { percentage: number }) =>
    dataPoint && dataPoint.percentage !== null ? dataPoint.percentage : -1;

  const size = (dataPoint: { total_opportunities: string }) =>
    dataPoint ? calculateSize(dataPoint.total_opportunities) : "0px";

  const findDataSet = (dataSet: any[], label: string) =>
    dataSet.find((ds) => ds.name === label);

  const findDataPoint = (dataSets: any[], yLabel: string, xLabel: string) =>
    findDataSet(dataSets, yLabel)?.data.find(
      (item: { name: string }) => item.name === xLabel
    );

  const isDataAvailable = (dataSet: undefined) => dataSet !== undefined;

  return (
    <div className="heatmap-container" id={id}>
      <div className="heatmap-grid margin-top">
        <div className="heatmap-row">
          <div className="heatmap-cell header"></div>
          {xLabels.map((label: string, idx: Key) => (
            <div key={idx} className="heatmap-cell header header-x">
              {String(label)}
            </div>
          ))}
        </div>
        {yLabels.map((yLabel: string, rowIndex: Key) => (
          <div key={rowIndex} className="heatmap-row">
            <div className="heatmap-cell header header-y">{String(yLabel)}</div>
            {xLabels.map((xLabel: string, colIndex: Key) => {
              const dataPoint = findDataPoint(dataSets, yLabel, xLabel);

              return (
                <div className="heatmap-cell">
                  <span
                    key={colIndex}
                    className="heatmap-cell bubble-cell"
                    style={{
                      backgroundColor: isDataAvailable(dataPoint)
                        ? getColor(percentage(dataPoint))
                        : "white",
                      width: isDataAvailable(dataPoint)
                        ? size(dataPoint)
                        : "40px",
                      height: isDataAvailable(dataPoint)
                        ? size(dataPoint)
                        : "40px",
                    }}
                    data-tip
                    data-for={`tooltip-${rowIndex}-${colIndex}`}
                  >
                    {isDataAvailable(dataPoint) ? (
                      <span id={`tooltip-${rowIndex}-${colIndex}`}>
                        {dataPoint && dataPoint.percentage !== -1
                          ? `${percentage(dataPoint)}%`
                          : ""}
                      </span>
                    ) : (
                      <span>No Data</span>
                    )}
                    <Tooltip
                      anchorId={`tooltip-${rowIndex}-${colIndex}`}
                      content={`Opportunities: ${
                        dataPoint
                          ? formatNumberWithCommas(
                              dataPoint.total_opportunities
                            )
                          : "N/A"
                      }`}
                      place="top"
                      className="custom-heatmap-tooltip"
                      classNameArrow="tooltip-arrow"
                      style={{
                        backgroundColor: "white",
                        color: "#0F1828",
                        borderRadius: "4px",
                        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                      }}
                    ></Tooltip>
                  </span>
                  <span className="vertical-grid-lines grid-lines">
                    <hr className="vertical"></hr>
                  </span>
                  <span className="horizontal-grid-lines grid-lines">
                    <hr className="horizontal"></hr>
                  </span>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CircularHeatmap;
