import { SetStateAction, useState } from "react";
import { missingDataAction, missingDataMessage } from "../../constants";
import BarChart from "../BarChart/BarChart";
import { MonthlySnapshotOptions } from "../BarChart/MonthlySnapshotOptions";
import MissingData from "../MissingData/MissingData";

interface PerformanceData {
  x: number;
  y: number;
  labels?: string;
}
interface DataSets {
  label: string;
  data: PerformanceData[];
  backgroundColor: string;
  borderColor: string;
  categoryPercentage: number;
}

type FacilityPerformanceReportBarGraphProps = {
  labels: string[];
  performantOpportunitiesData: PerformanceData[];
  nonPerformantOpportunitiesData: PerformanceData[];
  title: string;
  id?: string;
};

enum Heading {
  All = 0,
  Performant = 1,
  NonPerformant = 2,
}

function FacilityPerformanceReportBarGraph(
  props: FacilityPerformanceReportBarGraphProps
) {
  const labelCount = props.labels ? props.labels.length : 0;

  const getHeight = (labelCount: number): string => {
    if (labelCount < 3) return "200px";
    else if (labelCount >= 30) return "1500px";
    else if (labelCount >= 20) return "1000px";
    else if (labelCount > 10) return "750px";
    else return "500px";
  };

  const [currentHeading, setCurrentHeading] = useState(0);

  const showChart = (index: SetStateAction<number>) => {
    setCurrentHeading(index);
  };

  function getLabelsByHeading(
    currentHeading: Heading,
    props: {
      labels: any;
      performantOpportunitiesData: PerformanceData[];
      nonPerformantOpportunitiesData: PerformanceData[];
    }
  ) {
    let data;
    if (currentHeading === Heading.All) {
      return props.labels;
    } else if (currentHeading === Heading.Performant) {
      data = props.performantOpportunitiesData;
    } else if (currentHeading === Heading.NonPerformant) {
      data = props.nonPerformantOpportunitiesData;
    }
    return data
      .sort((a: { x: number }, b: { x: number }) => b.x - a.x)
      .map((data: { labels: string }) => data.labels);
  }

  const labels = getLabelsByHeading(currentHeading, props);

  let data = {
    labels: labels,
    datasets: [] as DataSets[],
  };

  if (currentHeading === Heading.All) {
    data.datasets.push({
      label: "Performant Opportunities",
      data: props.performantOpportunitiesData ?? [],
      backgroundColor: "rgba(0, 165, 251, 0.7)",
      borderColor: "rgba(0, 165, 251, 1)",
      categoryPercentage: 1,
    } as DataSets);
    data.datasets.push({
      label: "Non Performant Opportunities",
      data: props.nonPerformantOpportunitiesData ?? [],
      backgroundColor: "rgba(255, 105, 120, 0.8)",
      borderColor: "rgba(255, 105, 120, 1)",
      categoryPercentage: 1,
    } as DataSets);
  }

  if (currentHeading === Heading.Performant) {
    data.datasets.push({
      label: "Performant Opportunities",
      data:
        props.performantOpportunitiesData.sort((a, b) => a.x - b.x).reverse() ??
        [],
      backgroundColor: "rgba(0, 165, 251, 0.7)",
      borderColor: "rgba(0, 165, 251, 1)",
      categoryPercentage: 1,
    } as DataSets);
  }

  if (currentHeading === Heading.NonPerformant) {
    data.datasets.push({
      label: "Non Performant Opportunities",
      data:
        props.nonPerformantOpportunitiesData
          .sort((a, b) => a.x - b.x)
          .reverse() ?? [],
      backgroundColor: "rgba(255, 105, 120, 0.8)",
      borderColor: "rgba(255, 105, 120, 1)",
      categoryPercentage: 1,
    } as DataSets);
  }

  const buttons = [
    "Combined Performant and Non performant Opportunities",
    "Performant Opportunities",
    "Non-Performant Opportunities",
  ];

  return (
    <div>
      {props.nonPerformantOpportunitiesData.length === 0 &&
      props.performantOpportunitiesData.length === 0 ? (
        <MissingData message={missingDataMessage} action={missingDataAction} />
      ) : (
        <div>
          <div className="display-flex display-flex-space-between-buttons margin-bottom">
            {buttons.map((label: string, index: number) => (
              <button
                data-testid={`button-${index}`}
                key={index}
                className={`margin-right ${
                  currentHeading === index ? "active-legend-button" : ""
                }`}
                onClick={() => showChart(index)}
              >
                {label}
              </button>
            ))}
          </div>

          <div style={{ height: getHeight(labelCount) }} id={props.id}>
            <BarChart
              options={MonthlySnapshotOptions}
              isExecPage={true}
              style={{ height: getHeight(labelCount) }}
              data={data}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FacilityPerformanceReportBarGraph;
