import { NavLink } from "react-router-dom";
import { useUserInfo } from "../../contexts/UserInfo/userInfo";

// icons
import Building from "@untitled-ui/icons-react/build/cjs/Building07";
// import CalendarCheck01 from "@untitled-ui/icons-react/build/cjs/CalendarCheck01";

function NavigationNursingInsights() {
  const { userInfo } = useUserInfo();
  // loading bug where pages stick to scroll state of pervious page.
  const scrollToTop = () => {
    document.querySelector("body")?.scrollTo(0, 0);
  };

  return (
    <>
      <div className="navigation-content">
        <div>
          {userInfo?.permissions?.includes("NI.report.places") && (
            <>
              <li>
                <NavLink to="/NI/rounding" onClick={scrollToTop}>
                  <Building />
                  Rounding Dashboard
                </NavLink>
              </li>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NavigationNursingInsights;
