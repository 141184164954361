import { UnitReportDTO } from "../../model/types";
import { ApiDataError, UnitReport } from "../../types/types";
import { convertToXYPairs, formatPercentage, isEmptyArray } from "../../utils";
import {
  filterHtmlTags,
  findBestTimePeriod,
  transformFilters,
  transformTiles,
} from "../utils";

export function transformUnitReport(
  responseJson: UnitReportDTO | null
): UnitReport {
  let startDate, endDate;
  const dateRange = responseJson?._params?.date_range?.value;

  if (!dateRange) {
    startDate = responseJson?.subtitle?.startDate;
    endDate = responseJson?.subtitle?.endDate;
  } else {
    startDate = new Date(dateRange[1]);
    endDate = new Date(dateRange[2]);
  }

  const tiles = responseJson?.summaryBlocks?.tiles;
  const dataSeries = responseJson?.handHygienePlot?.dataSeries;

  const response: UnitReport = {
    tiles: transformTiles(tiles),
    bestTimePeriod: dataSeries ? findBestTimePeriod(dataSeries) : null,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    yAxisPlotlines: null,
    dataSeries: [],
    groupDataSeries: [],
    topPerformers: {},
    filters: [],
    error: null,
    excelUrl: "",
    uuid: "",
    entryExitData: {
      Entry: "",
      Exit: "",
    },
    shiftData: {},
    weekdayWeekendData: {
      weekday: "",
      weekend: "",
    },
    userHistogram: [{ x: 0, y: 0 }],
    userHistogramDrilldownData: {},
  };

  if (!responseJson) {
    return response;
  }

  response.filters = transformFilters(responseJson._params);

  const isApiDataError = (
    error: any[] | ApiDataError
  ): error is ApiDataError => {
    return !isEmptyArray(error);
  };

  // the api will return an empty array for _error if there are no errors
  // but will return an error object if there is an error
  if (isApiDataError(responseJson._error)) {
    response.error = responseJson._error;
  } else {
    response.error = null;
  }

  const yAxisPlotlines = responseJson?.handHygienePlot?.yAxisPlotlines;

  if (yAxisPlotlines) {
    response.yAxisPlotlines = yAxisPlotlines;
  }

  if (dataSeries && dataSeries.length > 0) {
    const sanitizedDataSeries = filterHtmlTags(dataSeries);
    response.dataSeries = sanitizedDataSeries;
  }

  const groupDataSeries = responseJson?.handHygieneByGroupPlot?.dataSeries;

  if (groupDataSeries && groupDataSeries.length > 0) {
    // this is all to remove html tags from the various data series names
    const sanitizedDataSeries = filterHtmlTags(groupDataSeries);
    response.groupDataSeries = sanitizedDataSeries;
  }

  const topPerformers = responseJson?.topPerformersTable?.users;

  if (topPerformers && Object.keys(topPerformers).length > 0) {
    response.topPerformers = topPerformers;
  }

  response.excelUrl = responseJson?._export?.url;

  response.uuid = responseJson?._report?.uuid;

  response.entryExitData.Entry =
    responseJson?.entryExitData &&
    responseJson?.entryExitData?.performanceByEntryExit.Entry !== null
      ? formatPercentage(
          responseJson.entryExitData.performanceByEntryExit.Entry
        ) + "%"
      : "No Data";

  response.entryExitData.Exit =
    responseJson?.entryExitData &&
    responseJson?.entryExitData?.performanceByEntryExit?.Exit !== null
      ? formatPercentage(
          responseJson.entryExitData.performanceByEntryExit?.Exit
        ) + "%"
      : "No Data";

  response.weekdayWeekendData.weekday =
    responseJson?.weekdayWeekendData &&
    responseJson?.weekdayWeekendData?.performanceByWeekdayWeekend?.Weekday !==
      null
      ? formatPercentage(
          responseJson.weekdayWeekendData?.performanceByWeekdayWeekend?.Weekday
        ) + "%"
      : "No Data";

  response.weekdayWeekendData.weekend =
    responseJson?.weekdayWeekendData &&
    responseJson?.weekdayWeekendData?.performanceByWeekdayWeekend?.Weekend !==
      null
      ? formatPercentage(
          responseJson.weekdayWeekendData?.performanceByWeekdayWeekend?.Weekend
        ) + "%"
      : "No Data";

  if (responseJson?.shiftData) {
    Object.entries(responseJson?.shiftData.performanceByShift).map(
      ([key, value]) => {
        return (response.shiftData[key] = formatPercentage(value) + "%");
      }
    );
  }

  const histogramData = responseJson?.userHistogram?.dataSeries[0]?.data;

  response.userHistogram = convertToXYPairs(histogramData);

  response.userHistogramDrilldownData =
    responseJson?.userHistogramDrilldownData;

  return response;
}
