import { performanceRanges } from "../../constants";

function getPerformanceLabel(xValue: number) {
  const range = performanceRanges.find((r) => r.x === xValue);
  return range ? range.label : "";
}

export const histogramOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return "Amount of Providers by Hand Hygiene Performance";
        },
        label: function (context) {
          const performance = getPerformanceLabel(context.parsed.x);
          return `Amount of Providers: ${Math.round(
            context.parsed.y
          )}% | Hand Hygiene Performance: ${performance}`;
        },
      },
    },
  },

  scales: {
    x: {
      type: "linear" as const,
      offset: false,
      ticks: {
        beginAtZero: true,
        stepSize: 10,
        callback: function (value: string) {
          return value + "%";
        },
      },
      grid: {
        offset: false,
      },
      title: {
        display: true,
        text: "Average Hand Hygiene Performance (%)",
        font: {
          weight: "bold" as "bold",
        },
      },
    },
    y: {
      title: {
        display: true,
        text: "Number of Providers by percentage (%)",
        font: {
          weight: "bold" as "bold",
        },
      },
      ticks: {
        beginAtZero: true,
        callback: function (value: string) {
          return value + "%";
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};
