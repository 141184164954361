import { defer } from "react-router-dom";
import { get } from "../../api/laravelApi";
import {
  staffOverviewSummaryEndpoint,
  staffOverviewTableEndpoint,
} from "../../constants";

export const StaffOverviewLoader = async ({ request }) => {
  const customerIds = new URL(request.url).searchParams.getAll("customersIds");
  const facilityIds = new URL(request.url).searchParams.getAll("facilitiesIds");
  const unitIds = new URL(request.url).searchParams.getAll("unitsIds");
  const groupIds = new URL(request.url).searchParams.getAll("groupsIds");
  const startDate = new URL(request.url).searchParams.get("startDate");
  const endDate = new URL(request.url).searchParams.get("endDate");

  const summary = get(staffOverviewSummaryEndpoint, {
    customerIds,
    facilityIds,
    unitIds,
    groupIds,
    startDate,
    endDate,
  });

  const rooms = get(staffOverviewTableEndpoint, {
    customerIds,
    facilityIds,
    unitIds,
    groupIds,
    startDate,
    endDate,
  });

  return defer({
    summary,
    rooms,
  });
};
