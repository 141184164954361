import { convertNumToString } from "../../utils";
import Cards from "../UI/Cards/Cards";

import Performant from "@untitled-ui/icons-react/build/cjs/CheckCircleBroken";
import Total from "@untitled-ui/icons-react/build/cjs/HeartHand";
import MissingData from "../MissingData/MissingData";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";

type MetricsCardProps = {
  performant: number | null;
  total: number | null;
};

function MetricsCard(props: MetricsCardProps) {
  const { performant, total } = props;

  let missingData = (
    <MissingData
      className="margin-top"
      message="No Data found."
      action="Try a different selection or date range."
    />
  );

  return (
    <>
      <Cards fullWidth={true} className="full-height display-flex-center">
        {!performant && !total && missingData}
        <div className="metrics-container">
          {performant && (
            <div className="performant-opportunities vertically-align">
              <div className="title-tooltip-container">
                <div>
                  {" "}
                  <Tooltips
                    id="performantOpportunitiesTooltip"
                    content={tooltipContent.performantOpportunities["en-US"]}
                  />
                </div>
                <div>
                  <h2>Performant Opportunities </h2>
                </div>
              </div>
              {!performant ? (
                missingData
              ) : (
                <>
                  <div className="center-content metrics-svg">
                    <Performant />
                  </div>
                  <div>
                    <p className="semi-bold"></p>
                    <h1>{convertNumToString(performant)}</h1>
                  </div>
                </>
              )}
            </div>
          )}

          {total && (
            <div className="total-opportunities vertically-align">
              <div className="title-tooltip-container">
                <div>
                  {" "}
                  <Tooltips
                    id="totalOpportunitiesTooltip"
                    content={tooltipContent.totalOpportunities["en-US"]}
                  />
                </div>
                <div>
                  <h2>Total Opportunities</h2>
                </div>
              </div>
              {!total ? (
                missingData
              ) : (
                <>
                  <div className="center-content metrics-svg">
                    <Total />
                  </div>
                  <div>
                    <h1>{convertNumToString(total)}</h1>{" "}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Cards>
    </>
  );
}

export default MetricsCard;
