import Cards from "../UI/Cards/Cards";
import MissingData from "../MissingData/MissingData";
import { missingDataAction, missingDataMessage } from "../../constants";
import TabsList from "../UI/Tabs/Tabs";
import GroupTypePerformance from "../GroupTypePerformance/GroupUnitTypePerformance";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";

const ExecDashboardGrid = ({ dataCurrentM, dataPreviousM, category, id }) => {
  return (
    <>
      <Cards
        headerLeft={<h2>{"Performance by " + category + " type"}</h2>}
        headerRight={
          <Tooltips
            content={tooltipContent.groupUnitTypePerformance["en-US"]}
            id={category + "-performance-tooltip"}
          ></Tooltips>
        }
      >
        {dataCurrentM.length !== 0 && dataPreviousM !== 0 ? (
          <>
            <TabsList
              forceRenderTabPanel={true}
              tabs={["Current Month", "Previous Month"]}
              content={[
                dataCurrentM.length !== 0 ? (
                  <GroupTypePerformance
                    id={id + "-current"}
                    data-testid={id + "-current"}
                    data={dataCurrentM}
                    category={category}
                  />
                ) : (
                  <MissingData
                    message={missingDataMessage}
                    action={missingDataAction}
                  />
                ),
                dataPreviousM.length !== 0 ? (
                  <GroupTypePerformance
                    id={id + "-previous"}
                    data-testid={id + "-previous"}
                    data={dataPreviousM}
                    category={category}
                  />
                ) : (
                  <MissingData
                    message={missingDataMessage}
                    action={missingDataAction}
                  />
                ),
              ]}
            />
          </>
        ) : (
          <MissingData
            message={missingDataMessage}
            action={missingDataAction}
          />
        )}
      </Cards>
    </>
  );
};

export default ExecDashboardGrid;
