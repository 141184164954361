import DatePicker from "../../Components/DatePicker/DatePicker";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import Filters from "../../Components/Filters/Filters";
import HardwareButton from "../../Components/HardwareButton/HardwareButton";
import RoundingPerformance from "../../Components/RoundingPerformance/RoundingPerformance";
import RoundingMetricsCard from "../../Components/RoundingMetricsCard/RoundingMetricsCard";

import Cards from "../../Components/UI/Cards/Cards";
import { oneWeekDateRange } from "../../constants";
import useRoundingViewController from "../../viewcontroller/Rounding/useRoundingViewController";
import LineGraph from "../../Components/LineGraph/LineGraph";
import TabsList from "../../Components/UI/Tabs/Tabs";
import { tooltipContent } from "../../content";

import Table from "@untitled-ui/icons-react/build/cjs/LayoutAlt03";
import Bubbles from "@untitled-ui/icons-react/build/cjs/IntersectCircle";
import LineChart from "@untitled-ui/icons-react/build/cjs/LineChartUp01";
import BubbleChartNI from "../../Components/BubbleChart/BubbleChartNI";
import GeneratePdf from "../../Components/PDF/Pdf";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";
import RoundingTable from "../../Components/RoundingTable/RoundingTable";
import ApiError from "../../Components/ApiError/ApiError";
import { useEffect } from "react";
import { useUUIDWithLocation } from "../../Hooks/useUuid";
import DatePickerDropdown from "../../Components/DatePickerDropdown/DatePickerDropdown";

function RoundingView() {
  const {
    transformedRoundingData: roundingData,
    aggregate,
    handleDateChange,
    filters,
    onFilterChange,
    toggleButton,
    disableExcelDoc,
    roundingDataTable,
    bubbleChartData,
    filterIsSelected,
    applyFiltersWithDataTableValues,
    error,
    disabledButton,
    handleDatePickerChange,
    isDatePickerVisible,
    handleDatePickerButtonClick,
    transformedBarGraphData,
  } = useRoundingViewController();

  const { updateUUID } = useUUIDWithLocation();

  useEffect(() => {
    updateUUID(roundingData?.uuid);
  }, [roundingData?.uuid, updateUUID]);

  const { allNodes } = roundingData;

  const generateTableData = (averages, index) => ({
    head: averages[index].shifts.map(
      (shift) => shift.name + " " + averages[index].title + " "
    ),
    body: [
      averages[index].shifts.map((shift) =>
        convertSecondsToHoursMinutesSeconds(shift.mean)
      ),
    ],
  });

  const tableTwo = generateTableData(allNodes?.averages, 0);
  const tableThree = generateTableData(allNodes?.averages, 1);
  const tableFour = generateTableData(allNodes?.averages, 2);

  return (
    <>
      <div className="page-header">
        <h1>Rounding Dashboard</h1>
        <div className="buttons-container">
          <ExcelDownload
            link={roundingData.excelUrl}
            disabled={disableExcelDoc}
          />
          <GeneratePdf
            pdfName={"ni-rounding"}
            id="ni-rounding"
            startDate={roundingData.startDate?.toDateString()}
            endDate={roundingData.endDate?.toDateString()}
            tableOneHead={["Total Performance"]}
            tableOneBody={[[roundingData.allNodes.totalPerformance + "%"]]}
            tableTwoHead={tableTwo.head}
            tableTwoBody={tableTwo.body}
            tableThreeHead={tableThree.head}
            tableThreeBody={tableThree.body}
            tableFourHead={tableFour.head}
            tableFourBody={tableFour.body}
          />
          <HardwareButton
            link={
              process.env.REACT_APP_AUTH_DOMAIN + "/dashboard/vitalSigns/node"
            }
          />
        </div>
      </div>
      {error && (
        <ApiError
          message={
            "Date range cannot exceed 7 days when aggregating by day. Please make a different selection."
          }
        />
      )}
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <div>
              <DatePickerDropdown
                startDateLabel={
                  roundingData.startDate ?? oneWeekDateRange.startDate
                }
                endDateLabel={roundingData.endDate ?? oneWeekDateRange.endDate}
                onChange={handleDatePickerChange}
              />
              {isDatePickerVisible && (
                <DatePicker
                  maxNumDays={7}
                  aggregate={aggregate}
                  handleDatePickerChange={() => handleDatePickerChange}
                  visible={isDatePickerVisible}
                  startDate={
                    roundingData.startDate ?? oneWeekDateRange.startDate
                  }
                  endDate={roundingData.endDate ?? oneWeekDateRange.endDate}
                  onChange={handleDateChange}
                  handleClick={handleDatePickerButtonClick}
                />
              )}
            </div>
            <Filters
              filters={filters}
              onChange={onFilterChange}
              toggleButton={toggleButton}
              applyFilters={applyFiltersWithDataTableValues}
              disabled={disabledButton}
            />
          </div>
        </Cards>
      </div>

      <div className="overview-pages-grid">
        <div className="performance-card">
          <RoundingPerformance
            totalPerformance={roundingData.allNodes.totalPerformance}
          />
        </div>
        <div className="ni-metrics-card">
          <RoundingMetricsCard barGraphData={transformedBarGraphData} />
        </div>
        <div className="graph-card-ni negative-margin-top-2x">
          <TabsList
            tooltipContent={tooltipContent.roundingGraphsToggle["en-US"]!}
            tooltipId={"roundingGraphsToggle"!}
            tabs={["Line Graph", "Table", "Bubble"]}
            content={[
              <>
                <LineGraph
                  title={"Rounding Performance Over Time"}
                  dataSeries={roundingData?.dataSeries}
                />
              </>,
              <RoundingTable
                isFilterSelected={filterIsSelected}
                data={roundingDataTable}
              />,
              <BubbleChartNI
                title={"Rounding Performance Opportunity Analysis"}
                data={bubbleChartData}
              />,
            ]}
            icons={[
              <LineChart className="tab-icon" />,
              <Table className="tab-icon" />,
              <Bubbles className="tab-icon" />,
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default RoundingView;
