import { useCallback, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Logo from "../assets/logo.png";
import { formatDate } from "../utils";
import autoTable from "jspdf-autotable";

declare module "jspdf" {
  interface jsPDF {
    previousAutoTable: {
      finalY: number;
    };
  }
}

const usePdfDownload = (
  elementIds?: string[],
  titles?: string[],
  tables?: { title?: string; head: any[][]; body: any[][] }[],
  filename = "download.pdf",
  documentTitle = "PDF Document",
  headingBackgroundColor = "#6A41C6"
) => {
  const [loading, setLoading] = useState(false); // Add loading state
  const downloadPdf = useCallback(async () => {
    if (!elementIds || elementIds.length === 0) {
      console.error("No element IDs provided.");
      return;
    }

    if (!titles || titles.length !== elementIds.length) {
      console.error(
        "Titles array must be the same length as element IDs array."
      );
      return;
    }

    setLoading(true);

    try {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
      });

      // Logo at the top of the PDF
      const logo = new Image();
      logo.src = Logo;
      const logoWidth = 60;
      const logoHeight = 60;
      const logoX = (pdf.internal.pageSize.getWidth() - logoWidth) / 2;
      const logoY = 20;
      pdf.addImage(logo.src, "JPEG", logoX, logoY, logoWidth, logoHeight);

      // "Generated on" below logo
      pdf.setFontSize(8);
      pdf.text(
        "Generated on: " + formatDate(new Date()),
        pdf.internal.pageSize.getWidth() / 2,
        logoY + logoHeight + 10,
        { align: "center" }
      );

      // Adjust yOffset to start below the logo and add extra spacing
      let yOffset = logoY + logoHeight + 40;

      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Renders Charts First
      const fixedWidth = 1200;
      const scalingFactor = 4;
      const graphMarginBottom = 40;
      const titleMarginBottom = 0;
      const titleHeight = 20;

      // Temporarily make all tab panels visible using inline styles
      const tabPanels = document.querySelectorAll(".tab-panel");
      const originalStyles = new Map<
        HTMLElement,
        { width: string; style: string }
      >();

      tabPanels.forEach((panel) => {
        originalStyles.set(panel as HTMLElement, {
          width: (panel as HTMLElement).style.width,
          style: panel.getAttribute("style") || "",
        });
        (panel as HTMLElement).style.display = "block";
        (panel as HTMLElement).style.position = "relative";
        (panel as HTMLElement).style.visibility = "visible";
        (panel as HTMLElement).style.height = "auto";
        (panel as HTMLElement).style.width = `${fixedWidth}px`;
      });

      for (let i = 0; i < elementIds.length; i++) {
        const element = document.getElementById(elementIds[i]);
        if (!element) {
          console.error(`Element with ID "${elementIds[i]}" not found.`);
          continue;
        }

        const originalElementStyle = element.getAttribute("style") || "";
        element.style.width = `${fixedWidth}px`;

        // ensures styles are fully applied before capturing the content
        await new Promise((resolve) => setTimeout(resolve, 100));

        try {
          // Capture the content using html2canvas with fixed width
          const canvas = await html2canvas(element, {
            scale: scalingFactor,
            width: fixedWidth,
            backgroundColor: "#FFFFFF",
            useCORS: true,
            logging: true,
          });

          const imgData = canvas.toDataURL("image/png");
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
          const newWidth = imgWidth * ratio;
          const newHeight = imgHeight * ratio;

          const xOffset = (pdfWidth - newWidth) / 2;

          if (
            yOffset + newHeight + titleHeight + graphMarginBottom >
            pdfHeight
          ) {
            pdf.addPage();
            yOffset = 40;
          }

          pdf.setFontSize(12);
          pdf.text(titles[i], pdfWidth / 2, yOffset, { align: "center" });
          yOffset += titleHeight + titleMarginBottom;

          pdf.addImage(imgData, "PNG", xOffset, yOffset, newWidth, newHeight);
          yOffset += newHeight + graphMarginBottom;
        } catch (error) {
          console.error("Error capturing element with html2canvas:", error);
        } finally {
          element.setAttribute("style", originalElementStyle);
        }
      }

      tabPanels.forEach((panel) => {
        const originalStyleData = originalStyles.get(panel as HTMLElement) || {
          width: "",
          style: "",
        };
        (panel as HTMLElement).style.width = originalStyleData.width;
        (panel as HTMLElement).setAttribute("style", originalStyleData.style);
      });

      // Step 2: Render Tables at the Bottom
      if (tables && tables.length > 0) {
        tables.forEach((table) => {
          if (table.head && table.body) {
            if (yOffset + 40 > pdfHeight) {
              pdf.addPage();
              yOffset = 40;
            }

            pdf.setFontSize(12);
            if (table.title) {
              pdf.text(
                table.title,
                pdf.internal.pageSize.getWidth() / 2,
                yOffset,
                {
                  align: "center",
                }
              );
            }
            yOffset += 20;

            autoTable(pdf, {
              startY: yOffset,
              head: table.head,
              body: table.body,
              headStyles: {
                fillColor: headingBackgroundColor,
              },
            });

            yOffset = (pdf as any).lastAutoTable.finalY + 20;
          }
        });
      }

      pdf.setProperties({ title: documentTitle });
      pdf.save(filename);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false); // Hide loading spinner after PDF generation is completed
    }
  }, [
    elementIds,
    titles,
    tables,
    filename,
    documentTitle,
    headingBackgroundColor,
  ]);

  return { downloadPdf, loading };
};

export default usePdfDownload;
