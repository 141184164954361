import { tooltipContent } from "../../content";
import DownloadButton from "../DownloadButton/DownloadButton";
import Tooltips from "../UI/Tooltip/Tooltip";

function ExcelDownload(props: { link: string; disabled?: boolean }) {
  let className;
  if (props.disabled) {
    className = "disabled";
  }

  return (
    <>
      {props.disabled && (
        <Tooltips
          id="excel-tooltip"
          content={tooltipContent.excelDownload["en-US"]}
        />
      )}
      <a className={className} href={props.link} data-testId={"excel-download"}>
        <DownloadButton cta={"Excel"} />
      </a>
    </>
  );
}

export default ExcelDownload;
