import { Await, useAsyncValue } from "react-router";
import { Suspense } from "react";
import Loading from "../../Components/UI/Loading/Loading";
import useStaffOverviewViewController from "../../viewcontroller/StaffOverview/useStaffOverviewViewController";

function StaffOverviewView() {
  const { staffOverviewData } = useStaffOverviewViewController();
  const { summary, rooms } = staffOverviewData;
  return (
    <>
      <Suspense
        fallback={
          <div className="fallback-loader">
            <Loading />
          </div>
        }
      >
        <Await resolve={summary}>
          <Summary />
        </Await>
      </Suspense>
      <Suspense
        fallback={
          <div className="fallback-loader">
            <Loading />
          </div>
        }
      >
        <Await resolve={rooms}>
          {(resolvedTable) => {
            return (
              <div>
                <h2>Table Data:</h2>
                <div>{JSON.stringify(resolvedTable)}</div>{" "}
                {/* Display table data */}
              </div>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}

function Summary() {
  const summary = useAsyncValue();
  return (
    <div>
      <h2>Summary:</h2>
      <div>{JSON.stringify(summary)}</div> {/* Display summary data */}
    </div>
  );
}

export default StaffOverviewView;
