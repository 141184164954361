import { Filter, GroupedOptions, Option } from "../../types/types";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useModuleTheme } from "../../contexts/ModuleTheme/moduleThemes";
interface FiltersProps {
  onChange: (filterName: string, updatedSelected: readonly Option[]) => void;
  filters: Filter[];
  toggleButton?: boolean;
  singleSelect?: boolean;
  applyFilters?: () => void;
  disabled?: boolean;
}

const styles = {
  control: (base) => ({
    ...base,
    height: "100%",
    border: "#e1e4e9 1px solid",
    boxShadow: "1px 1px 1px 0 rgba(16, 24, 40, 0.06)",
    "&:hover": {
      border: "#6a41c6 1px solid 0.75",
      boxShadow: "0 0 0 3px #f8f5fe",
    },
    fontFamily: "Inter",
    fontSize: "14px",
  }),
  menu: (base) => ({
    ...base,
    border: "0px solid black",
    fontFamily: "Inter",
    fontSize: "14px",
  }),
  input: (base) => ({
    ...base,
    minWidth: "125px",
    color: "black",
    border: "0px solid black",
    fontFamily: "Inter",
    fontSize: "14px",
  }),
};

const formatOptionLabel = (data, { context }) => {
  // Check if the context is 'value', which means this label is for the selected option
  if (context === "value" && data.group) {
    return `${data.group.label}: ${data.label}`;
  }

  return data.label;
};

export default function Filters({
  onChange,
  filters,
  toggleButton,
  singleSelect,
  applyFilters,
  disabled,
}: FiltersProps) {
  const { theme } = useModuleTheme();

  const [flashingButton, setFlashingButton] = useState(
    `flashing-button-${theme}`
  );
  useEffect(() => {
    setFlashingButton(`flashing-button-${theme}`);
  }, [theme]);

  return (
    <>
      {filters.map((filter) => {
        const value = filter.selection;

        const options: Option[] | GroupedOptions[] = filter.options;

        const handleDropdownChange = (updatedSelected: readonly Option[]) => {
          let updatedSelectedValues;
          if (Array.isArray(updatedSelected)) {
            updatedSelectedValues = [...updatedSelected];
          } else {
            updatedSelectedValues = [updatedSelected];
          }
          onChange(filter.name, updatedSelectedValues);
        };

        const capitalizeFirstLetter = (word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        };

        let isMulti = singleSelect ? false : true;
        let onChangeHandler = handleDropdownChange;
        let getDefaultValue;
        let isClearable = true;
        if (filter.name === "customers" && theme === "asset-tracking") {
          isMulti = false;
        }

        if (filter.name === "customers" && theme === "nursing-insights") {
          isMulti = false;
        }

        if (filter.name === "shift" && theme === "hand-hygiene") {
          isMulti = false;
        }

        if (filter.name === "aggregation") {
          isMulti = false;
        }

        let isVisible = true;
        const includesDynamicUrl = /staff/;
        if (theme === "hand-hygiene") {
          if (
            includesDynamicUrl.test(window.location.pathname) &&
            filter.name === "aggregation"
          ) {
            isVisible = false;
          }
        }

        if (!isMulti) {
          getDefaultValue = filter?.options[0]?.label;
          isClearable = false;
        }

        function getFilterLabel(filter, theme) {
          const filterName = capitalizeFirstLetter(filter.name);

          if (
            theme === "asset-tracking" &&
            ["units", "facilities"].includes(filter.name)
          ) {
            return `${filterName} location`;
          }

          const contactTracingLabels = {
            facilities: "Facility",
            customers: "Customer",
            users: "User",
            rooms: "Room",
          };

          return theme === "contact-tracing"
            ? contactTracingLabels[filter.name] || filterName
            : filterName;
        }

        const label = getFilterLabel(filter, theme);

        return (
          <div key={filter.name}>
            {isVisible && (
              <>
                <div className={"label-container " + theme}>
                  <label htmlFor={filter.name}>{label}</label>
                </div>
                <Select
                  styles={styles}
                  className="dropdown"
                  formatOptionLabel={formatOptionLabel}
                  options={options}
                  value={value}
                  isClearable={isClearable}
                  isSearchable
                  defaultValue={getDefaultValue}
                  isMulti={isMulti}
                  inputId={filter.name}
                  onChange={onChangeHandler}
                />
              </>
            )}
          </div>
        );
      })}
      {applyFilters && (
        <div className="apply-button-container">
          <div>
            <button
              className={`filters-margin themed select-button-height ${
                disabled ? "disabled" : ""
              } + "" + ${toggleButton ? theme + " " + flashingButton : ""}`}
              onClick={() => applyFilters()}
            >
              Apply Filters
            </button>
          </div>
        </div>
      )}
    </>
  );
}
