import { isTrendingDownward } from "../../utils";
import Carousel from "../Carousel/Carousel";
import Cards from "../UI/Cards/Cards";
import Trend from "react-trend";
import ChevronRight from "@untitled-ui/icons-react/build/cjs/ChevronRight";

type SparklineCardsProps = {
  data: Facility[];
  onClick: (facilityId: string) => void;
};

type Facility = {
  facility_id: string;
  facility_name: string;
  percentages: number[];
};

function SparklineCards(props: SparklineCardsProps) {
  const renderCards = (
    data: any[],
    onClick: {
      (facilityId: string): void;
      (facilityId: string): void;
      (arg0: any): void;
    }
  ) => {
    return data.map((facility, index) => (
      <Cards
        key={facility.facility_id}
        headerLeft={<h2>{facility.facility_name}</h2>}
        headerRight={<ChevronRight />}
        className={"margin-right"}
        onClick={() => onClick(facility.facility_id)}
      >
        {facility.percentages.length > 1 ? (
          <Trend
            gradient={
              isTrendingDownward(facility.percentages)
                ? ["#ff6978", "#ff6978", "#ff6978"]
                : ["#00a5fb", "#00a5fb", "#00a5fb"]
            }
            data-testid={"trend-component-" + index}
            data={facility.percentages}
          />
        ) : (
          <Trend
            gradient={["#707070", "#707070", "#707070"]}
            data-testid={"trend-component-" + index}
            data={[0, 0]}
          />
        )}
      </Cards>
    ));
  };

  return (
    <>
      {props.data.length > 3 ? (
        <Carousel>{renderCards(props.data, props.onClick)}</Carousel>
      ) : (
        <div className="display-flex">
          {renderCards(props.data, props.onClick)}
        </div>
      )}
    </>
  );
}

export default SparklineCards;
