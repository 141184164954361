import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";

function VisitOverview() {
  const location = useLocation();

  const selectedTab = location.pathname.includes("staff") ? 1 : 0;

  return (
    <>
      <div className="page-header">
        <h1>Visit Overview</h1>
      </div>
      <div className="Tabs">
        <div className="themed-tabs-container">
          <Tabs
            className="right-align"
            focusTabOnClick={false}
            selectedTabClassName="themed-tabs"
            selectedIndex={selectedTab}
          >
            <TabList>
              <div className="display-flex flex-wrap">
                <Tab>
                  <Link reloadDocument to="/NI/visit-overview/rooms">
                    Rooms Overview
                  </Link>
                </Tab>
                <Tab>
                  <Link to="/NI/visit-overview/staff">Staff Overview</Link>
                </Tab>
              </div>
            </TabList>
          </Tabs>
        </div>
      </div>

      <Outlet />
    </>
  );
}
export default VisitOverview;
