import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import Right from "@untitled-ui/icons-react/build/cjs/ArrowLeft";
import Left from "@untitled-ui/icons-react/build/cjs/ArrowRight";

function Carousel(props: { children: any }) {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1201) {
        setSlidesToShow(4);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <Left data-testid="left-arrow" />,
    prevArrow: <Right data-testid="right-arrow" />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>{props.children}</Slider>
    </div>
  );
}

export default Carousel;
