import { Await, useLoaderData } from "react-router";
import { Suspense } from "react";
import Loading from "../../Components/UI/Loading/Loading";
import Cards from "../../Components/UI/Cards/Cards";
import { RoomsOverviewDTO } from "../../model/types";
import FiltersWithDatePicker from "../../CompositeComponents/FiltersWithDatePicker";
import RoomsTable from "../../Components/VisitOverview/VisitOverviewTable";
import Summary from "../../Components/VisitOverview/VisitOverviewSummary";

function RoomsOverviewView() {
  const { summary, filters, roomsTable } = useLoaderData() as RoomsOverviewDTO;

  return (
    <>
      <div className="margin-bottom">
        <Cards>
          <div className="filters filters-margin-top">
            <Suspense
              fallback={
                <div className="fallback-loader">
                  <Loading />
                </div>
              }
            >
              <Await resolve={filters}>
                <FiltersWithDatePicker />
              </Await>
            </Suspense>
          </div>
        </Cards>
      </div>
      <div className="display-flex-visit-summary">
        <Suspense
          fallback={
            <div className="fallback-loader">
              <Loading />
            </div>
          }
        >
          <Await resolve={summary}>
            <Summary />
          </Await>
        </Suspense>
      </div>
      <div className="display-flex-visit-table">
        <div>
          <Suspense>
            <Await resolve={roomsTable}>
              <RoomsTable />
            </Await>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default RoomsOverviewView;
