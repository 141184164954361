import { Link } from "react-router-dom";

type LogoProps = {
  iconUrl: string;
  title?: string;
  className?: string;
  url: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  testid?: string;
  isModuleToggle?: boolean;
  isDisabled?: boolean;
};

function Logo(props: LogoProps) {
  const moduleToggleClassName = props.isModuleToggle
    ? "overview-select"
    : "module-select";
  const isDisabled = props.isDisabled ? "disabled-logo" : "";

  return (
    <>
      <div onClick={props.onClick} data-testId={props.testid}>
        <div className="Logo">
          <Link to={props.url} className={props.className}>
            <div
              data-testid="logo-test"
              className={
                moduleToggleClassName +
                " flex-container-logo" +
                " " +
                isDisabled
              }
            >
              <img src={props.iconUrl} alt="swipesense logo"></img>
              <h3 className="logo">{props.title}</h3>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Logo;
